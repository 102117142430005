@import 'styles/styles.scss';

.optionModal {
  padding: 5.2rem 3.8rem;

  &__img {
    display: block;
    margin: auto;
  }

  &__padding {
    padding-bottom: 3.2rem;
    text-align: left !important;
  }
  &__subtitle {
    @include lato-font($fontWeight: 400);
    margin-top: 1.5rem;
    margin-bottom: 5.5rem;
  }
  &__title {
    @include lato-font($size: 1.6rem);
    text-align: center;
  }
  &__text {
    margin-top: 1rem;
    @include lato-font($color: var(--color-alert-50));
    &:active,
    &:hover {
      @include lato-font($color: var(--color-alert-50));
    }
  }
  &__cancelBlue {
    @include lato-font($color: var(--color-accent-50));
  }
}
