@import 'styles/styles';

.loader {
  @include center-flex;
  background-color: var(--color-backdrop-2);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
}
