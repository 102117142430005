@import 'styles/styles';

.baseLoader {
  color: var(--color-accent-50);
  height: 10rem;
  position: relative;
  width: 10rem;

  &::after,
  &::before {
    content: '';
    animation: zoomOutSmall 2s linear infinite;
    border-radius: 50%;
    border: 5px solid var(--color-accent-50);
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::before {
    animation: zoomOut 2s linear 1s infinite;
    border-color: var(--color-accent-30);
  }
}
