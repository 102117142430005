@import 'mixins.scss';

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--color-gray-100);
  box-sizing: border-box;
  color: var(--color-black);
  font-family: var(--lato-font);
  font-weight: 300;
  line-height: 1.5;
}

html {
  font-size: 62.5%; // 1 rem = 10px
}

.paragraph {
  @include lato-font($size: 1.4rem, $fontWeight: 400, $color: 'inherit');
  line-height: 2rem;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.list {
  list-style-position: inside;
  margin-bottom: 2rem;
}

.listItem {
  @include lato-font($size: 1.4rem, $fontWeight: 400, $color: 'inherit');
}
