.navigation {
  background-color: var(--color-gray-100);
  border-top: 1px solid var(--color-gray-80);
  bottom: 0;
  color: var(--color-gray-80);
  height: var(--navigation-height);
  position: fixed;
  width: 100%;
  z-index: 1;

  &__list {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    padding: 1rem 2.9rem;
    list-style: none;
  }
}
