// center absolute positioned elements
@mixin center-horizontal-vertical {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-flex {
  align-items: center;
  display: flex;
  justify-content: center;
}
@mixin flex(
  $alignItems: center,
  $justify-content: center,
  $flex-direction: row,
  $flex-wrap: nowrap
) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
}

@mixin get-content-min-height($other-element-height) {
  min-height: calc(100vh - var(--navigation-height) - var(--header-height) - $other-element-height);
}

@mixin lato-font($size: 1.4rem, $fontWeight: 700, $color: var(--text-color)) {
  color: $color;
  font-family: var(--lato-font);
  font-size: $size;
  font-weight: $fontWeight;
}
/****
/* MEDIA QUERY MENAGER
/*****
0 - 400px: Small phone (mini)
[400px - 600px] - is where our normal styles apply
600 - 900px: Tablet portrait (tab-port)
900 - 1200px : Tablet landscape (tab-land)
1200 - 1800: Desktop (desktop)
1800px + : Big desktop
$breakpoint argument choices:
- mini
- tab-port
- tab-land
- desktop
- big-desktop
1em = 16px
*/

@mixin respond($breakpoint) {
  @if $breakpoint == micro {
    @media (max-width: 22.857rem) {
      @content;
    } // <= 350px
  }
  @if $breakpoint == mini {
    @media (max-width: 25em) {
      @content;
    } // <= 400px
  }
  @if $breakpoint == tab-port {
    @media (min-width: 37.5em) {
      @content;
    } // >= 600px
  }
  @if $breakpoint == tab-land {
    @media (min-width: 56.25em) {
      @content;
    } // >= 900px
  }
  @if $breakpoint == desktop {
    @media (min-width: 75em) {
      @content;
    } // >= 1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // >= 1800px
  }
}

/****
/* HEIGHT MEDIA QUERY MENAGER
/*****
0 - 690px: low phone (low)
[690px - 900px] - is where our normal styles apply
900px + : high screen (high)

$breakpoint argument choices:
- low
- high

1em = 16px
*/

@mixin respond-height($breakpoint) {
  @if $breakpoint == low {
    @media screen and (max-height: 43.13em) {
      @content;
    } // <= 690px
  }
  @if $breakpoint == high {
    @media screen and (min-height: 56.25em) {
      @content;
    } // >= 900px
  }
}
