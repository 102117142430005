@import 'styles/styles';

.modal {
  &__backdrop {
    @include center-flex;
    backdrop-filter: blur(2px);
    background-color: var(--color-backdrop);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  &__close {
    cursor: pointer;
    fill: var(--color-gray-10);
    height: 1.4rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    transition: all 0.5s;
    width: 1.4rem;

    &:hover {
      fill: var(--color-black);
    }
  }

  &__content {
    background-color: var(--color-gray-100);
    border-radius: 1.8rem;
    color: var(--text-color);
    margin: 2.5rem;
    padding: 2.2rem 2.9rem;
    position: fixed;
    top: 50%;
    transform: translateY(-50%) scale(1);
    width: calc(100% - 5rem);
    z-index: 1001;
  }

  // Transitions
  &__backdropEnter {
    opacity: 0;
  }
  &__backdropEnterActive {
    opacity: 1;
    transition: opacity 0.7s;
  }
  &__backdropExit {
    opacity: 1;
  }
  &__backdropExitActive {
    opacity: 0;
    transition: opacity 0.7s;
  }

  &__enter {
    opacity: 0;
    transform: translateY(-50%) scale(0.5);
  }
  &__enterActive {
    opacity: 1;
    transform: translateY(-50%) scale(1);
    transition: opacity 0.3s, transform 0.3s;
  }
  &__exit {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
  &__exitActive {
    opacity: 0;
    transform: translateY(-50%) scale(0.5);
    transition: opacity 0.3s, transform 0.3s;
  }
}
