@keyframes zoomOut {
  0% {
    transform: scale(0.3);
    opacity: 0.2;
  }

  50% {
    opacity: 0.9;
  }

  70% {
    opacity: 0.2;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes zoomOutSmall {
  0% {
    transform: scale(0);
    opacity: 0.2;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
