@import 'styles/styles';

.button {
  &,
  &:visited {
    @include lato-font($color: var(--color-gray-100));
    background-color: var(--color-accent-50);
    border-radius: 0.4rem;
    border: 1px solid var(--color-accent-50);
    cursor: pointer;
    @include flex();
    outline: none;
    padding: 1.2rem 0.8rem;
    transition: all 0.5s;
    width: 100%;
    @include respond(mini) {
      @include lato-font($size: 1.3rem, $color: var(--color-gray-100));
    }
    @include respond(micro) {
      @include lato-font($size: 1.1rem, $color: var(--color-gray-100));
    }
  }

  &:hover {
    background-color: var(--color-accent-30);
    border-color: var(--color-accent-30);
  }

  &_loading {
    @include center-flex;
  }

  &_disabled {
    background-color: var(--color-gray-80);
    border: 1px solid var(--color-gray-60);
    color: var(--color-gray-60) !important;
    cursor: not-allowed;

    &:hover {
      background-color: var(--color-gray-80);
      color: var(--color-gray-60);
    }
  }

  &_line {
    &,
    &:visited {
      @include lato-font($color: var(--color-accent-50));
      background-color: transparent;
      border: 1px solid var(--color-accent-50);
      @include respond(mini) {
        @include lato-font($size: 1.3rem, $color: var(--color-accent-50));
      }
      @include respond(micro) {
        @include lato-font($size: 1.1rem, $color: var(--color-accent-50));
      }
    }

    &:hover {
      @include lato-font($color: var(--color-accent-30));
      background-color: transparent;
      border: 1px solid var(--color-accent-30);
    }
  }

  &_line &_disabled {
    @include lato-font($color: var(--color-gray-80));
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-gray-80);
  }

  &_text {
    @include lato-font($color: var(--text-color));
    background-color: transparent;
    border: none;

    &:hover {
      @include lato-font($color: var(--text-color));
      background-color: transparent;
      border: none;
    }
  }
  &_danger {
    @include lato-font($color: var(--color-gray-100));
    background-color: var(--color-alert-50);
    border: none;

    &:hover {
      background-color: var(--color-alert-50);
    }
  }

  &__iconLoading {
    animation: rotate 1s linear infinite;
    fill: var(--color-gray-100);
    height: 1.8rem;
    width: 1.8rem;
  }
}
