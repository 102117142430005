.navItem {
  &,
  &:visited,
  &:focus,
  &:link {
    align-items: center;
    color: var(--color-gray-80);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-decoration: none;
  }

  &__active {
    color: var(--color-accent-50) !important;
  }

  &:hover {
    color: var(--color-accent-30);
  }

  &__icon {
    fill: var(--color-gray-80);
    height: 2rem;
  }

  &__title {
    font-family: var(--lato-font);
    font-size: 1.2rem;
    font-weight: 700;
  }
}
